import React from 'react'
import { useState } from 'react';
import {
    ProSidebar, Menu, MenuItem, SubMenu,
    SidebarHeader, SidebarFooter, SidebarContent
} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import { Logout } from '../auth/Logout';
import { ADMINISTRATOR } from '../auth/RouteRoles';
import GetLoginInfo from '../auth/_userContext/GetLoginInfo';
import { DEFAULT_USER_IMG, ERROR_IMG } from '../config/GlobalSettings';
import { BASE_URL } from '../initApi/apiUrls';
const initialsForSubMenus = {
    sub1: false,
    sub2: false,
    sub3: false,
}
export function MobileSidebar({ handleToggleSidebar, toggled }) {
    const [subMenuIsOpen, setSubMenuIsOpen] = useState(initialsForSubMenus)
    const userData = GetLoginInfo()
    const OnMenuClick = () => {
        handleToggleSidebar()
    }

    const onSubMenuOpenChange = (val, sunId) => {
        let initSub = { ...initialsForSubMenus, [sunId]: val }
        setSubMenuIsOpen(initSub)
    }

    return (
        <>
            <ProSidebar
                toggled={toggled}
                breakPoint="xxl"
                onToggle={handleToggleSidebar}
                style={{ marginTop: "0px", zIndex: "10009" }}>

                <SidebarHeader>
                    <div
                        style={{
                            padding: '18px',
                            //textTransform: 'uppercase',
                            //fontWeight: 'bold',
                            fontSize: 15,
                            //letterSpacing: '1px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            backgroundColor: "#438EB9",
                            color: "white"
                        }}
                    >
                        <div className="row">
                            <div className="col-xs-3" style={{ padding: "0px" }}>
                                <img className="img-round" style={{ margin: "0px" }} height="60px" width="60px" src={userData.isDP == 1 ? BASE_URL + "/" + userData.dpUrl : DEFAULT_USER_IMG}
                                    onError={(e) => { e.target.onerror = null; e.target.src = ERROR_IMG }}
                                />
                            </div>
                            <div className="col-xs-9">
                                <button type="button" class="close pull-right" style={{ fontSize: "28px" }} onClick={OnMenuClick}>×</button>
                                <span>
                                    <small>Welcome,</small>
                                    <br />
                                    <b>{userData.fullName}</b>

                                </span>
                            </div>
                        </div>
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu>
                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-tachometer blue"></i>}>
                            <Link to="/home"> Home </Link>
                        </MenuItem>
                        <MenuItem onClick={OnMenuClick} icon={<img height="22px" src='/images/sareeIco.png' />}>
                            <Link to="/drapingo"> Drap Now </Link>
                        </MenuItem>
                        <div class="hr"></div>
                        <MenuItem onClick={OnMenuClick} icon={<i class="menu-icon fa fa-search"></i>}>
                            <Link to="/rechargehistory">Recharge History</Link>
                        </MenuItem>
                        <MenuItem onClick={OnMenuClick} icon={<img height="12px" src='/images/recharge.gif' />}>
                            <Link to="/rechargeplans">Recharge Draping</Link>
                        </MenuItem>
                        <div class="hr"></div>
                        <MenuItem onClick={OnMenuClick} icon={<i class="ace-icon fa fa-key purple"></i>}>
                            <Link to="/changepassword">Change Password</Link>
                        </MenuItem>
                        <MenuItem onClick={OnMenuClick} icon={<i class="ace-icon fa fa-power-off red"></i>}>
                            <Logout />
                        </MenuItem>
                    </Menu>
                </SidebarContent>

                <SidebarFooter style={{ textAlign: 'center' }}>
                    <div
                        className="sidebar-btn-wrapper"
                        style={{
                            padding: '20px 24px',
                        }}
                    >
                        <p>
                            <span style={{ verticalAlign: "bottom" }}>Powered&nbsp;By </span><a href="https://cubicle8.com" target="_blank">
                                <img style={{ marginBottom: "3px" }} height="32px" src="images/cubiclelogo.gif" />
                            </a>
                        </p>
                    </div>
                </SidebarFooter>
            </ProSidebar>
        </>
    )
}
