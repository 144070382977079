import React from 'react';
import BarLoader from "react-spinners/BarLoader";
export function LazyLoadFull({ text = "Loading", size = 7, color = "#438EB9" }) {
    return (
        <div className='row'>
            <div className='col-md-12'>
                <div style={{ position: 'relative', textAlign: 'center' }}>
                    <img src='/images/placeholder.gif' width='100%' />
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <h3>
                            {text}
                            {/* <br />
                            <BarLoader color={color} loading size={size} /> */}
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
}