import { GetOrganizationId } from "../auth/_userContext/OrganizationID"
import { IsMobile } from "../initApi/apiUrls"

export const MOBILE_KEY = "UlwUaPRAsjhud7IhlZur0EdmbAsdfj782PvC60"
export const PAGING_PAGE_SIZE = 10
export const DEFAULT_USER_IMG = "/images/DefaultUser.png"
export const DEFAULT_IMG = "/images/default.png"
export const ERROR_IMG = "/images/error.png"
export const OrgId = () => GetOrganizationId()//"cubicle"
export const getPageMargins = () => {
    return `@page { margin: 1cm 1cm 1cm 1cm !important; }`;
};

export const getIsPrint = !IsMobile;