import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../../base/Container'
import AppToaster from '../../commonLib/AppToaster'
import { loadingMiniContext } from '../../commonLib/lodingMiniContext'
import * as sareeDrapingCrud from './../../_crud/sareeDrapingCrud'
import { DrapingRecharge } from './DrapingRecharge'

export function RechargePlansDashboard() {
    const [isLoadingMini, setIsLoadingMini] = useContext(loadingMiniContext)
    const [rechargeDraping, setRechargeDraping] = useState()
    const [selectedDrapingCount, setSelectedDrapingCount] = useState(10)
    const [rechargePlan, setRechargePlan] = useState(0)

    useEffect(() => {
        getRechargeDraping()
    }, [selectedDrapingCount])

    function getRechargeDraping() {
        setIsLoadingMini(true);
        sareeDrapingCrud.GetRechargeDrapingPlanInfo(selectedDrapingCount)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setRechargeDraping(hdata);
                        setIsLoadingMini(false)
                    }
                    else {
                        setIsLoadingMini(false)
                        setRechargeDraping();
                    }
                }
                else {
                    setIsLoadingMini(false)
                    setRechargeDraping();
                    AppToaster(res.data.result.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingMini(false);
                AppToaster(error, 'error')
            })
    }

    const ProceedToPlan = (plan) => {
        if (plan == 4 && rechargeDraping) {
            if (rechargeDraping.totalAmount > 0)
                setRechargePlan(plan)
            else
                AppToaster("Invalid recharge amount.", "warning")
        }
        else {
            setRechargePlan(0)
        }
    }

    return (
        <Container isProtected={false} title="Recharge Plans" isModelView={false}>
            <br />
            {rechargePlan == 0 &&
                <>
                    <div class="row">
                        <div class="col-sm-4"></div>
                        <div class="col-sm-4 pricing-span-header">
                            <div class="widget-box transparent">
                                <div class="widget-header">
                                    <h5 class="widget-title bigger lighter">Plan Name</h5>
                                </div>

                                <div class="widget-body">
                                    <div class="widget-main no-padding">
                                        <ul class="list-unstyled list-striped pricing-table-header">
                                            <li>Plan</li>
                                            <li>Per Draping <small>(inclusive gst)</small></li>
                                            <li>Net Amount</li>
                                            <li>GST %</li>
                                            <li>GST Amount</li>
                                            <li>Total</li>
                                            <li></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 pricing-span-body">
                            {rechargeDraping &&
                                <div class="pricing-span">
                                    <div class="widget-box pricing-box-small widget-color-red">
                                        <div class="widget-header">
                                            <h5 class="widget-title bigger lighter">Draping</h5>
                                        </div>

                                        <div class="widget-body">
                                            <div class="widget-main no-padding">
                                                <ul class="list-unstyled list-striped pricing-table">
                                                    <li style={{ padding: "0" }}>
                                                        <select style={{ height: "33px" }} className="form-control" value={selectedDrapingCount} onChange={(e) => setSelectedDrapingCount(e.target.value)}>
                                                            <option value="10" >10 Drapings</option>
                                                            <option value="20" >20 Drapings</option>
                                                            <option value="30" >30 Drapings</option>
                                                            <option value="40" >40 Drapings</option>
                                                            <option value="50" >50 Drapings</option>
                                                            <option value="60" >60 Drapings</option>
                                                            <option value="70" >70 Drapings</option>
                                                            <option value="80" >80 Drapings</option>
                                                            <option value="90" >90 Drapings</option>
                                                            <option value="100" >100 Drapings</option>
                                                        </select>
                                                    </li>
                                                    <li>₹ {Number(rechargeDraping.perDrapingRate).toFixed(2)}</li>
                                                    <li>₹ {Number(rechargeDraping.netAmount).toFixed(2)}</li>
                                                    <li>{rechargeDraping.gstSlab}%</li>
                                                    <li>₹ {Number(rechargeDraping.gstAmount).toFixed(2)}</li>
                                                    <li className="bolder green">₹ {Number(rechargeDraping.totalAmount).toFixed(2)}</li>
                                                    <li className="bolder"><br />-</li>
                                                </ul>
                                            </div>

                                            <div>
                                                <button class="btn btn-block btn-sm btn-danger" onClick={() => ProceedToPlan(4)}>
                                                    <span>Buy</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-8">
                            <dl id="dt-list-1">
                                <dt>Note</dt>
                                <dd>Terms and Conditions applied.</dd>
                            </dl>
                        </div>
                    </div>
                </>
            }
            {rechargePlan == 4 &&
                <DrapingRecharge setRechargePlan={setRechargePlan} data={rechargeDraping} />
            }
        </Container>
    )
}
