import React from 'react'

export function Paging(props) {

    const { LastPage, CurrentPage, OnPageChange, pagingData } = props;

    return (
        <>
            <div className="message-footer clearfix" style={{ padding: "0px 16px" }}>
                <div className="pull-left">
                    <h5 style={{ marginTop: "25px" }}>
                        {pagingData &&
                            (pagingData.firstItemOnPage) + " - " + (pagingData.lastItemOnPage) + " Total " + (pagingData.totalItemCount) + " Items (Page No. " + pagingData.pageNumber + " Total Page " + pagingData.pageCount + ")"
                        }
                    </h5>
                </div>

                <div className="pull-right">

                    <ul className="pager">
                        <li className="previous" >

                            <button disabled={(LastPage == 1 || LastPage == 0) ? true : (CurrentPage == 1 ? true : false)} className="btn btn-white btn-info" value={parseInt(CurrentPage) - 1} onClick={OnPageChange}>← Newer</button>

                        </li>

                        <li className="next" >

                            <button disabled={(LastPage == 1 || LastPage == 0) ? true : (CurrentPage == LastPage ? true : false)} style={{ marginLeft: "20px" }} className="btn btn-white btn-info" value={parseInt(CurrentPage) + 1} onClick={OnPageChange}>Older →</button>

                        </li>
                    </ul>
                </div>
            </div>

            {props.children}

            <div className="message-footer clearfix" style={{ padding: "0px 16px", marginTop: "-20px" }}>
                <div className="pull-left">
                    <h5 style={{ marginTop: "25px" }}>
                        {pagingData &&
                            (pagingData.firstItemOnPage) + " - " + (pagingData.lastItemOnPage) + " Total " + (pagingData.totalItemCount) + " Items (Page No. " + pagingData.pageNumber + " Total Page " + pagingData.pageCount + ")"
                        }
                    </h5>
                </div>

                <div className="pull-right">

                    <ul className="pager">
                        <li className="previous" >

                            <button disabled={(LastPage == 1 || LastPage == 0) ? true : (CurrentPage == 1 ? true : false)} className="btn btn-white btn-info" value={parseInt(CurrentPage) - 1} onClick={OnPageChange}>← नया</button>

                        </li>

                        <li className="next" >

                            <button disabled={(LastPage == 1 || LastPage == 0) ? true : (CurrentPage == LastPage ? true : false)} style={{ marginLeft: "20px" }} className="btn btn-white btn-info" value={parseInt(CurrentPage) + 1} onClick={OnPageChange}>पुराना →</button>

                        </li>
                    </ul>
                </div>
            </div>
        </>

    )
}
