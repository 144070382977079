import React, { useContext, useEffect, useState } from 'react';
import { RouteContainer } from '../RouteContainer';
import { authContext } from './../auth/_userContext/authContext';
import { intervalContext } from '../auth/_userContext/intervalContext';
import { TopMenu } from './TopMenu';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from './Breadcrumbs';
import { useSwipeable } from 'react-swipeable';
import { Capacitor } from "@capacitor/core";
import { App } from "@capacitor/app";

export function Content({ handleToggleSidebar, toggled }) {
    const history = useHistory()

    const handlers = useSwipeable({
        onSwipedLeft: () => BackExit(),
        //onSwipedRight: () => handleToggleSidebar(true),
        swipeDuration: 150,
        preventScrollOnSwipe: false,
        trackMouse: false,
        trackTouch: true,
    });

    function BackExit() {
        if (Capacitor.isNativePlatform) {
            if (history.location.pathname === "/") {
                // Show A Confirm Box For User to exit app or not
                let ans = window.confirm("Are you sure to Exit");
                if (ans) {
                    App.exitApp()
                }
            } else if (history.location.pathname === "/home") {
                // Show A Confirm Box For User to exit app or not
                let ans = window.confirm("Are you sure to Exit");
                if (ans) {
                    App.exitApp()
                }
            }
        }

    }

    const [isLogin, setIsLogin] = useContext(authContext)
    const [intervalId, setIntervalId] = useContext(intervalContext);

    // const countDown = () => {
    //     try {
    //         CheckAuth()
    //     } catch (error) {
    //         ClearLoginInfo();
    //         setIsLogin(false);
    //         history.push("/login")
    //     }
    // }

    // useEffect(() => {
    //     try {
    //         //CheckAuth()
    //         let intId = setInterval(countDown, 60000);
    //         setIntervalId(intId)

    //     } catch (error) {
    //         ClearLoginInfo();
    //         setIsLogin(false);
    //         history.push("/login")
    //     }

    // }, [])

    // function CheckAuth() {
    //     //AppToaster("Auth Called", "success")
    //     try {
    //         let oid = GetOrganizationId()
    //         if (oid) {
    //             let loginData = GetLoginInfo();
    //             if (loginData) {
    //                 createAxiosWithToken(loginData.token, OrgId()).post("LoginValidate/" + GetEUserRoleName(loginData.role)).then(result => {
    //                     if (result) {
    //                         if (result.data.succeeded) {
    //                         }
    //                         else {
    //                             if (result.data.Code === 107) {
    //                                 //refresh login
    //                                 createAxios(OrgId()).post("Authentication/RefreshLogin", {
    //                                     userId: loginData.id,
    //                                     refreshToken: loginData.refreshToken,
    //                                     isPersistent: loginData.isPersistent
    //                                 }).then(refreshResult => {
    //                                     if (refreshResult) {
    //                                         if (refreshResult.data.result.succeeded) {
    //                                             AppToaster("Login Refreshed.", "info");

    //                                             let oid = loginData.orgId
    //                                             ClearLoginInfo();
    //                                             let d = refreshResult.data
    //                                             d = { ...d, orgId: oid }
    //                                             SetLoginInfo(d)
    //                                             setIsLogin(true);
    //                                         }
    //                                         else {
    //                                             AppToaster("Refresh Expired.", "error");
    //                                             setIsLogin(false);
    //                                             ClearLoginInfo();
    //                                         }
    //                                     }
    //                                     else {
    //                                         setIsLogin(false);
    //                                         ClearLoginInfo();
    //                                     }
    //                                 }).catch(error => {
    //                                     AppToaster("Please check internet connection.", "error");
    //                                 });
    //                             }
    //                             else {
    //                                 setIsLogin(false);
    //                                 ClearLoginInfo();
    //                             }
    //                         }
    //                     }
    //                     else {

    //                         setIsLogin(false);
    //                         ClearLoginInfo();
    //                     }
    //                 }).catch(error => {
    //                     setIsLogin(false);
    //                     ClearLoginInfo();
    //                 });
    //             }
    //             else {
    //                 setIsLogin(false);
    //                 ClearLoginInfo();
    //             }
    //         }
    //         else {
    //             setIsLogin(false);
    //             ClearLoginInfo();
    //         }
    //     }
    //     catch (error) {
    //         AppToaster(error + "Please check internet connection.", "error");
    //     }
    // }

    return (
        <>
            <TopMenu handleToggleSidebar={handleToggleSidebar} />
            <div class="main-container ace-save-state" id="main-container" {...handlers}>
                {/* <Menu /> */}
                <div class="main-content">
                    <div class="main-content-inner">
                        {/* <Breadcrumbs /> */}
                        <RouteContainer />
                    </div>
                </div>
            </div>
        </>
    );
}