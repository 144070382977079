import React, { useContext, useState } from 'react'
import AppToaster from '../../commonLib/AppToaster';
import * as globalCrud from '../../_crud/globalCrud';
import * as Yup from 'yup'
import { useFormik } from 'formik';

export function OrgRegistrationForm({ token, phone, setIsCreatedOrg }) {
    const initialValues = {
        fullName: "",
        mobileNo: phone,
        password: "",
        confirmPassword: "",
    }

    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isAgree, setIsAgree] = useState(0)

    const Schema = Yup.object().shape({
        fullName: Yup.string().required("Full Name is required.").min(3, "Full name mus min 3 and max 30 characters.").max(30, "Full name mus min 3 and max 30 characters."),
        password: Yup.string()
            .required('Please Enter your new password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
            ),
        confirmPassword: Yup.string()
            .required('Please Enter your confirm password')
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            Save(values);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const Save = (value) => {
        setIsLoadingLocal(true)
        globalCrud.CreateNewOrgGlobal({
            fullName: value.fullName,
            mobileNo: value.mobileNo,
            password: value.password,
            token: token
        }, "cubicle")
            .then(res => {
                if (res.data.succeeded) {
                    setIsLoadingLocal(false);
                    formik.setValues(initialValues)
                    setIsCreatedOrg(true)
                    AppToaster('Registered successfully.', 'success')
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster(res.data.message, 'error')
                }
            })
            .catch(error => {
                alert(error)
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }


    return (
        <>
            <form onSubmit={formik.handleSubmit} class="form-horizontal" autocomplete="off">
                <div className="row">
                    <div className="col-md-4">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Full Name</label>
                                <input className="form-control" type="text" placeholder="Full Name" name="fullName" {...formik.getFieldProps("fullName")} />
                                {
                                    formik.touched.fullName &&
                                        formik.errors.fullName ? (
                                        <label className="text-danger" >
                                            {formik.errors.fullName}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Mobile No.</label>
                                <input disabled className="form-control" type="text" placeholder="Mobile No." name="mobileNo" {...formik.getFieldProps("mobileNo")} />
                                {
                                    formik.touched.mobileNo &&
                                        formik.errors.mobileNo ? (
                                        <label className="text-danger" >
                                            {formik.errors.mobileNo}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Password</label>
                                <input className="form-control" type="password" placeholder="Password" name="password" {...formik.getFieldProps("password")} />
                                {
                                    formik.touched.password &&
                                        formik.errors.password ? (
                                        <label className="text-danger" >
                                            {formik.errors.password}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <label class="control-label no-padding-right">Confirm Password</label>
                                <input className="form-control" type="password" placeholder="Confirm Password" name="confirmPassword" {...formik.getFieldProps("confirmPassword")} />
                                {
                                    formik.touched.confirmPassword &&
                                        formik.errors.confirmPassword ? (
                                        <label className="text-danger" >
                                            {formik.errors.confirmPassword}
                                        </label>
                                    ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4">
                        <label class="block">
                            <input onChange={() => setIsAgree(isAgree == 1 ? 0 : 1)} checked={isAgree == 1} type="checkbox" class="ace" />
                            <span class="lbl">
                                &nbsp;I accept the
                                <a href="https://baithka.com/useragreement" target="_blank">&nbsp;User Agreement</a>
                            </span>
                        </label>


                        <div class="clearfix">
                            {isLoadingLocal ?
                                <>
                                    <button disabled class="width-65 btn btn-sm btn-success">
                                        <span class="bigger-110">Please wait</span>
                                        <i class="ace-icon fa fa-arrow-right icon-on-right"></i>
                                    </button>
                                </>
                                :
                                <button disabled={!formik.isValid || isAgree == 0} class="width-65 btn btn-sm btn-success">
                                    <span class="bigger-110">Register</span>
                                    <i class="ace-icon fa fa-arrow-right icon-on-right"></i>
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
