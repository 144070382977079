import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../base/Container'
import { organizationContext } from './Organization/organizationContext';
import * as reportCrud from './../_crud/reportCrud'
import * as organizationCrud from './../_crud/organizationCrud'
import * as contactCrud from './../_crud/contactCrud'
import GetLoginInfo from '../auth/_userContext/GetLoginInfo';
import SetLoginInfo from '../auth/_userContext/SetLoginInfo';
import { Logout } from '../auth/Logout';
import { DrapingBalance } from './SareeDraper/DrapingBalance';
import { Link, useHistory } from 'react-router-dom';

export default function Home() {
    const [organization, setOrganization] = useContext(organizationContext);
    const [counts, setCounts] = useState()
    const userData = GetLoginInfo()
    const id = userData.userName
    const history = useHistory()
    useEffect(() => {
        GetGlobalCountContact()
    }, []);

    function GetGlobalCountContact() {
        reportCrud.GetGlobalCountContact(id)
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setCounts(hdata)
                    }
                    else {
                    }
                }
                else {
                }
            })
            .catch(error => {
            })
    }

    useEffect(() => {
        getOrganization()
        getContact()
    }, []);


    function getOrganization() {
        organizationCrud.GetOrganization()
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        setOrganization(hdata)
                    }
                    else {
                    }
                }
                else {
                }
            })
            .catch(error => {
            })
    }

    function getContact() {
        contactCrud.GetById(id)
            .then(res => {
                if (res.data.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data.data
                        if (hdata.status == 1) {
                            let d = userData
                            d = { ...d, isDP: hdata.isPhoto, dpUrl: hdata.photoUrl }
                            SetLoginInfo(d)
                        }
                        else {
                            Logout()
                        }
                    }
                    else {
                    }
                }
                else {
                }
            })
            .catch(error => {
            })
    }
    function NewDraping() {
        history.replace("/drapingo");
        history.push('/loading');
        history.goBack();
    }
    return (
        <Container title="Home">
            <div class="clearfix">
                <div className="row">
                    <div className="col-md-12">
                        <div class="center">
                            <DrapingBalance />
                            <br />
                            <button class="btn btn-md bigger-120 btn-info" onClick={() => { NewDraping() }}>
                                <i class="ace-icon fa fa-plus"></i> New Draping
                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div class="center">
                            <h3>Drapingo Tutorial</h3>
                            <iframe width="100%" height="400px"
                                src="https://www.youtube.com/embed/ou-t9UL0lf8">
                            </iframe>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
