import React, { useContext, useState } from 'react';
import { Login } from './components/auth/Login';
import { Content } from './components/base/Content'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { authContext } from './components/auth/_userContext/authContext';
import { Error404 } from './components/pages/others/404';
import { MobileSidebar } from './components/base/MobileSidebar';
import { SignUp } from './components/auth/SignUp/SignUp';
import { ForgotPassword } from './components/auth/ForgotPassword/ForgotPassword';

export function Main() {
  const [isLogin, setIsLogin] = useContext(authContext);
  const [toggled, setToggled] = useState(false);
  const location = useLocation();

  const handleToggleSidebar = (value) => {
    if (toggled)
      setToggled(false);
    else
      setToggled(true);

  };

  return (
    <>
      {(isLogin) ?
        <>
          <MobileSidebar toggled={toggled} handleToggleSidebar={handleToggleSidebar} />

          <Content toggled={toggled} handleToggleSidebar={handleToggleSidebar} />
        </>
        :
        <>
          <Redirect to={(location.pathname == "/" || location.pathname == "/login") ? "/login" : ((location.pathname == "/signup" || location.pathname == "/forgot") ? location.pathname : "/404")} />
          <Route path="/login"><Login /></Route>
          <Route path="/signup"><SignUp /></Route>
          <Route path="/forgot"><ForgotPassword /></Route>
          <Route path="/404"><Error404 /></Route>
        </>
      }
    </>
  );
}