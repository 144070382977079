import React from 'react'

export function StatusView({ status, ...props }) {

    const GetType = () => {
        switch (status) {
            case 0: return "danger";
            case 1: return "success";
            case 2: return "purple";
            case 3: return "primary";
            case 4: return "pink";
            default: return "default";
        }
    }

    return (
        <span class={"label label-sm label-" + GetType()}>
            {props.children}
        </span>
    )
}
