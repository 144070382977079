import React, { useContext } from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { DiskSpaceLowMessage } from '../commonLib/DiskSpaceLowMessage'
import { ExpireMessage } from '../commonLib/ExpireMessage'
import { Spinner } from '../commonLib/Spinner'
import { loadingContext } from '../commonLib/_lodingContext'
import { diskSizeContext } from '../pages/Global/Elements/diskSizeContext'
import { organizationContext } from '../pages/Organization/organizationContext'
import { DashboardTitle } from './DashboardTitle'
import { Footer } from './Footer'

export function Container({ title, doRefresh,
    isBack = false,
    isContact = false,
    isProduction = false,
    isProduct = false,
    isProcessing = false,
    isOrder = false,
    isVoucher = false,
    isModelView, ...props }) {
    const history = useHistory()
    const [isLoading, setIsLoading] = useContext(loadingContext)
    const [organization, setOrganization] = useContext(organizationContext);
    const [diskSize, setDiskSize] = useContext(diskSizeContext);

    useEffect(() => {
        document.title = title + " - DrapinGo Baithka - A TEXTILE MANUFACTURING"
    }, [])

    return (
        <>
            {
                isModelView ?
                    props.children
                    :
                    <div class="page-content">
                        <div class="page-header center">
                            <h3 style={{marginTop:"0px", marginBottom:"-4px"}}>{isBack && <a style={{ cursor: "pointer" }} onClick={() => history.goBack()}><i class="menu-icon fa fa-arrow-left"></i></a>}<img height="75px" src="images/drapingo.png" />{doRefresh && <a onClick={doRefresh} title="Reload" style={{ marginLeft: "10px", cursor: "pointer" }}>
                                <i class="ace-icon fa fa-refresh green"></i>
                            </a>}
                            </h3>
                        </div>
                        <div class="row">
                            <div class="col-xs-12">
                                {isLoading ? <Spinner /> : props.children}
                            </div>
                        </div>

                        <br />
                        <br />
                        <Footer />
                    </div>
            }
        </>
    )
}
