import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import DiskSizeProvider from './components/pages/Global/Elements/diskSizeContext';
import OrganizationProvider from './components/pages/Organization/organizationContext';

ReactDOM.render(
  <React.StrictMode>
    <OrganizationProvider>
      <DiskSizeProvider>
        <App />
      </DiskSizeProvider>
    </OrganizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);