import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom'
import { OPERATION } from './auth/RouteRoles';
import { ProtectedRoute } from './../components/auth/ProtectedRoute';
import Home from './pages/Home';
import ChangePassword from './pages/Settings/ChangePassword';
import { Drapingo } from './pages/SareeDraper/Drapingo';
import { RechargePlansDashboard } from './pages/RechargePlans/RechargePlansDashboard';
import { ManageRechargeHistory } from './pages/RechargePlans/ManageRechargeHistory';

export function RouteContainer() {
    const location = useLocation();
    return (
        <>
            <Redirect to={location.pathname == "/login" || location.pathname == "/" ? "/home" : location.pathname} />

            <ProtectedRoute role={OPERATION} path="/dashboard" component={<h1>Dashboard</h1>} />
            <ProtectedRoute role={OPERATION} path="/home" component={Home} />
            <ProtectedRoute role={OPERATION} path="/changepassword" exact component={ChangePassword} />
            <ProtectedRoute role={OPERATION} path="/drapingo" exact component={Drapingo} />
            <ProtectedRoute role={OPERATION} path="/rechargeplans" exact component={RechargePlansDashboard} />
            <ProtectedRoute role={OPERATION} path="/rechargehistory" exact component={ManageRechargeHistory} />
            <Route path="/loading">
                <></>
            </Route>


            {/* <Route path="/404">
                <Error404 />
            </Route>
            <Redirect from='*' to='/404' /> */}

        </>
    );
}