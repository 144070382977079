import axios from 'axios'
import { GetOrganizationId } from '../auth/_userContext/OrganizationID'
import { MOBILE_KEY } from '../config/GlobalSettings'
import { BASE_URL_API, IsMobile } from './apiUrls'

const createAxiosSimple = axios.create()

const createAxios =(oid)=> axios.create({
    baseURL: BASE_URL_API,
    headers: {
        "mydb": oid,
        "mobile": IsMobile ? MOBILE_KEY : ""
    }
})

const createAxiosWithToken = (token, oid) => axios.create({
    baseURL: BASE_URL_API,
    headers: {
        Authorization: 'Bearer ' + token,
        "mydb": oid,
        "mobile": IsMobile ? MOBILE_KEY : ""
    }
})

const createAxiosWithTokenFile = (token, oid) => axios.create({
    baseURL: BASE_URL_API,
    headers: {
        Authorization: 'Bearer ' + token,
        ContentType: 'multipart/form-data',
        "mydb": oid,
        "mobile": IsMobile ? MOBILE_KEY : ""
    }
})

export { createAxiosWithToken, createAxios, createAxiosWithTokenFile, createAxiosSimple }