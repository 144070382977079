import GetLoginInfo from "./GetLoginInfo";
import Keys from "./Keys";
var CryptoJS = require("crypto-js");

const SetOrganizationId = (id) => {
    var ciphertext = CryptoJS.AES.encrypt("cubicle", Keys).toString();
    localStorage.setItem('ot', ciphertext);
}

const GetOrganizationId = () => {
    return "cubicle"
}

export { SetOrganizationId, GetOrganizationId }