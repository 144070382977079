import { createAxios } from "../initApi/createAxios";

export async function CreateNewOrgGlobal(param, orgid) {
    return await createAxios(orgid).post("Register/CreateNewDrapingUser", param)
}
export async function MobileOtpGenerate(param, orgid) {
    return await createAxios(orgid).post("Register/MobileOtpGenerateDraping", param)
}
export async function MobileOtpVerify(param, orgid) {
    return await createAxios(orgid).post("Register/MobileOtpVerifyDraping", param)
}