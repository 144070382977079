import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";

export async function DrapingSuccess() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("DrapingoUsers/DrapingSuccess")

    }
}

export async function GetDrapingBalance() {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("DrapingoUsers/GetDrapingBalance")

    }
}
export async function GetAllRechargeByUser(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("DrapingoUsers/GetAllRechargeByUser", param)
    }
}

export async function GetRechargeDrapingPlanInfo(count) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("DrapingoUsers/GetRechargeDrapingPlanInfo?Count=" + count)
    }
}

export async function InitRechargeDraping(count) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("DrapingoUsers/InitRechargeDraping?Count=" + count)
    }
}

export async function AfterRechargeDraping(rechargeID, paymentId, signature) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("DrapingoUsers/AfterRechargeDraping?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&signature=" + signature)
    }
}
export async function FailedRechargeDraping(rechargeID, paymentId, note) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("DrapingoUsers/FailedRechargeDraping?rechargeID=" + rechargeID + "&paymentId=" + paymentId + "&note=" + note)
    }
}

