import Auth from "./components/auth/_userContext/authContext";
import Interaval from "./components/auth/_userContext/intervalContext";
import { Main } from "./Main";
import Loading from "./components/commonLib/_lodingContext";
import MiniLoading from "./components/commonLib/lodingMiniContext";
import { ToastContainer } from 'react-toastify';
import Id from "./components/pages/idContext";
import DashboardProvider from "./components/pages/Global/dashboardContext";
import "react-datepicker/dist/react-datepicker.css";
import { organizationContext } from "./components/pages/Organization/organizationContext";
import AppToaster from "./components/commonLib/AppToaster";
import { useContext, useEffect, useState } from "react";
import * as organizationCrud from './components/_crud/organizationCrud';
import * as reportCrud from './components/_crud/reportCrud';
import { diskSizeContext } from "./components/pages/Global/Elements/diskSizeContext";
//import 'react-pro-sidebar/dist/css/styles.css';
import "./components/base/MobileSidebarStyle.scss"
import { BrowserRouter as Router, HashRouter, Switch } from 'react-router-dom'

function App() {
  const [organization, setOrganization] = useContext(organizationContext);
  const [diskSize, setDiskSize] = useContext(diskSizeContext);

  useEffect(() => {
    getOrganization()
    getSize()
  }, []);

  function getOrganization() {
    organizationCrud.GetOrganization()
      .then(res => {
        if (res.data.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data.data
            setOrganization(hdata)
          }
          else {
            AppToaster("Network Error.", 'error')
          }
        }
        else {
          AppToaster("Network Error.", 'error')
        }
      })
      .catch(error => {
        AppToaster(error, 'error')
      })
  }

  function getSize() {
    reportCrud.GetSizeUsed()
      .then(res => {
        if (res.data) {
          if (res.data.result.succeeded) {
            let hdata = res.data
            setDiskSize(hdata)
          }
          else {
            AppToaster("Network Error.", 'error')
          }
        }
        else {
          AppToaster("Network Error.", 'error')
        }
      })
      .catch(error => {
        AppToaster(error, 'error')
      })
  }
  return (
    <>
      <Interaval>
        <Auth>
          <Loading>
            <MiniLoading>
              <Id>
                <DashboardProvider>
                  <Router>
                    <HashRouter>
                      <Switch>
                        <Main />
                      </Switch>
                    </HashRouter>
                  </Router>
                </DashboardProvider>
              </Id>
            </MiniLoading>
          </Loading>
        </Auth>
      </Interaval>
      <ToastContainer />
    </>
  );
}

export default App;
