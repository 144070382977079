import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { createAxios, createAxiosWithToken, createAxiosWithTokenFile } from "../initApi/createAxios";
import { OrgId } from "../config/GlobalSettings";
import { Alert } from "react-bootstrap";

export async function GetAll(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Contact/GetAll", param)
    }
}

export async function GetByDocumentIdNo(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Contact/GetByDocumentIdNo?PageNo=" + param.pageNumber + "&PageSize=" + param.pageSize + "&SearchTerm=" + param.searchText + "&DocumentIdNo=" + param.documentIdNo + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}

export async function GetByStatus(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Contact/GetByStatus?PageNo=" + param.pageNumber + "&PageSize=" + param.pageSize + "&SearchTerm=" + param.searchText + "&Status=" + param.searchValue + "&FromDate=" + param.fromDate + "&ToDate=" + param.toDate)
    }
}

export async function GetByPhone(Phone) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Contact/GetByPhone?Phone=" + Phone)
    }
}

export async function GetById(id) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).get("Contact/GetById?Id=" + id)
    }
}

export async function Add(contact) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Contact/Add", contact)
    }
}
export async function Edit(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithToken(loginData.token, OrgId()).post("Contact/Edit", param)
    }
}

export async function UploadDocument(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("Contact/UploadDocument", param)
    }
}
export async function UploadPhoto(param) {
    const loginData = GetLoginInfo();
    if (loginData) {
        return await createAxiosWithTokenFile(loginData.token, OrgId()).post("Contact/UploadPhoto", param)
    }
}