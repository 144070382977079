import React, { useState, useEffect } from 'react'
import * as sareeDrapingCrud from './../../_crud/sareeDrapingCrud'
import { Spinner } from '../../commonLib/Spinner';
import AppToaster from '../../commonLib/AppToaster';
import { BeatLoader } from 'react-spinners';

export function DrapingBalance() {
    const [organization, setOrganization] = useState();
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)

    useEffect(() => {
        getData()
    }, [])

    function getData() {
        setIsLoadingLocal(true);
        sareeDrapingCrud.GetDrapingBalance()
            .then(res => {
                if (res.data) {
                    if (res.data.result.succeeded) {
                        let hdata = res.data
                        setOrganization(hdata)
                        setIsLoadingLocal(false)

                    }
                    else {
                        setIsLoadingLocal(false)
                    }
                }
                else {
                    setIsLoadingLocal(false)
                    AppToaster("Server Error /Invalid Try.", 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
            })
    }

    return (
        <>
            {isLoadingLocal ?
                <div className='well well-sm'>
                    <h4 style={{ marginBottom: "0px" }} class="blue smaller lighter"><span>Draping Balance <BeatLoader t color="#438EB9" loading={(isLoadingLocal)} size={7} /></span></h4>
                </div>
                :
                <div className='well well-sm'>
                    {organization &&
                        <>
                            <h4 style={{ marginBottom: "0px" }} class="blue smaller lighter">Balance <i class="ace-icon fa fa-long-arrow-right"></i> <span className='green'>{organization.balance} draping available</span> | total {organization.used} draping used. <a onClick={getData} title="Reload" style={{ marginLeft: "10px", cursor: "pointer" }}>
                                <i class="ace-icon fa fa-refresh blue"></i>
                            </a> </h4>
                        </>
                    }
                </div>
            }
        </>
    )
}
