import React from 'react'
import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import AppToaster from '../../commonLib/AppToaster';
import * as globalCrud from '../../_crud/globalCrud';
import { useEffect } from 'react';

const initialValues = {
    MobileNo: ""
}
export function MobileVerify({setReadyOrg, setToken, setPhone}) {
    const [initRegistration, setInitRegistration] = useState(false);
    const [startCounter, setStartCounter] = useState(false);
    const [otpData, setOtpData] = useState({ mobileNo: "", otp: "" });
    const [isLoadingLocal, setIsLoadingLocal] = useState(false)
    const [isLoadingLocal2, setIsLoadingLocal2] = useState(false)
    const [counter, setCounter] = useState(60);

    useEffect(() => {
        if (startCounter) {
            counter > 0 ? setTimeout(() => setCounter(counter - 1), 1000) : setStartCounter(false);
        }
        else {
            setCounter(0)
            setStartCounter(false)
        }
    }, [counter])

    const Schema = Yup.object().shape({
        MobileNo: Yup.string().required("Mobile No is required.").matches(
            /^([\d]{10})$/g,
            "Invalid phone number"
        ),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values) => {
            //SendOTP();
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    const SendOTP = () => {
        setIsLoadingLocal(true);
        globalCrud.MobileOtpGenerate({
            MobileNo: formik.values.MobileNo
        }, "cubicle")
            .then(res => {
                if (res.data.result.succeeded) {
                    setStartCounter(true)
                    setCounter(60)
                    setOtpData({ mobileNo: res.data.mobileNo, otp: res.data.otp })
                    setInitRegistration(true);
                    setIsLoadingLocal(false);
                    AppToaster('Otp sent on your mobile successfully.', 'success')
                }
                else {
                    setIsLoadingLocal(false);
                    AppToaster(res.data.result.message, 'error')
                }

            })
            .catch(error => {
                setIsLoadingLocal(false);
                AppToaster(error, 'error')
                alert(error)
            })
    }
    const ValidateOTP = () => {
        setIsLoadingLocal2(true);
        globalCrud.MobileOtpVerify({
            MobileNo: otpData.mobileNo,
            Otp: otpData.otp
        }, "cubicle")
            .then(res => {
                if (res.data.succeeded) {
                    setPhone(otpData.mobileNo)
                    setToken(res.data.message);
                    setReadyOrg(true)
                    setIsLoadingLocal2(false);
                    AppToaster('Otp sent on your mobile successfully.', 'success')
                }
                else {
                    setOtpData({ ...otpData, otp: "" })
                    setIsLoadingLocal2(false);
                    AppToaster(res.data.message, 'error')
                }
            })
            .catch(error => {
                setIsLoadingLocal2(false);
                AppToaster(error, 'error')
            })
    }
    const ChangeMobile = () => {
        setOtpData({ mobileNo: "", otp: "" })
        setInitRegistration(false);
        setCounter(0)
        setStartCounter(false)
    }
    return (
        <>
            <div>
                <div className="row">
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                        <fieldset>
                            <label class="block clearfix">
                                {!initRegistration ?
                                    <div>
                                        <label for="form-field-mask-1">
                                            Mobile No <small>(eg: 98XXXXXX23)</small>
                                        </label>

                                        <div class="input-group">
                                            <input class="form-control" placeholder="Mobile No (eg: 98XXXXXX23)" name="MobileNo" {...formik.getFieldProps("MobileNo")} />
                                            <span class="input-group-btn">
                                                {isLoadingLocal ?
                                                    <button disabled class="btn btn-sm btn-info">
                                                        Sending...
                                                    </button>
                                                    :
                                                    <button onClick={SendOTP} disabled={!formik.isValid} class="btn btn-sm btn-info">
                                                        Send OTP
                                                    </button>
                                                }
                                            </span>
                                        </div>
                                        {
                                            formik.touched.MobileNo &&
                                                formik.errors.MobileNo ? (
                                                <label className="text-danger" >
                                                    {formik.errors.MobileNo}
                                                </label>
                                            ) : null
                                        }
                                    </div>
                                    :
                                    <div>
                                        <label for="form-field-mask-1">
                                            Mobile No <small>(eg: 98XXXXXX23)</small>
                                        </label>

                                        <div class="input-group">

                                            <div class="form-control" style={{ backgroundColor: "#e9e9e9" }}> {formik.values.MobileNo} </div>

                                            {!startCounter ?
                                                <span class="input-group-btn">
                                                    {isLoadingLocal ?
                                                        <button disabled class="btn btn-sm btn-info">
                                                            Sending...
                                                        </button>
                                                        :
                                                        <button onClick={() => { SendOTP(); }} disabled={!formik.isValid} class="btn btn-sm btn-info">
                                                            Resend OTP
                                                        </button>
                                                    }
                                                </span>
                                                :
                                                <span>Resend OTP in {counter} seconds</span>
                                            }
                                        </div>
                                    </div>
                                }

                            </label>
                            {initRegistration &&
                                <label class="block clearfix">
                                    <span class="block input-icon input-icon-right">
                                        <input class="form-control" placeholder="OTP" value={otpData.otp} onChange={(e) => setOtpData({ ...otpData, otp: e.target.value })} type="password" />
                                        <i class="ace-icon fa fa-key"></i>
                                    </span>
                                </label>
                            }
                        </fieldset>
                    </div>
                </div>
                {initRegistration &&
                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <div class="clearfix">
                                {isLoadingLocal2 ?
                                    <button disabled class="pull-right btn btn-sm btn-success">
                                        <span class="bigger-110">Please wait</span>
                                        <i class="ace-icon fa fa-arrow-right icon-on-right"></i>
                                    </button>
                                    :
                                    <>
                                        <button onClick={ValidateOTP} class="pull-right btn btn-sm btn-success" disabled={!otpData.otp}>
                                            <span class="bigger-110">Verify</span>
                                            <i class="ace-icon fa fa-arrow-right icon-on-right"></i>
                                        </button>

                                        <a onClick={ChangeMobile} style={{ cursor: "pointer" }}>
                                            <span class="bigger-110">
                                                <i class="ace-icon fa fa-pencil"></i> Change Mobile
                                            </span>
                                        </a>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
