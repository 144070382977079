import GetLoginInfo from "../auth/_userContext/GetLoginInfo";
import { OrgId } from "../config/GlobalSettings";
import { createAxios, createAxiosSimple, createAxiosWithToken } from "../initApi/createAxios";

export async function MMSUser(userName, password, isPersistent) {
    //alert("Id = " + userName + "Pass =" + password)
    const isper = isPersistent ? 1 : 0;
    return await createAxios(OrgId()).post("Authentication/MMSUser",
        { userName: userName, password: password, isPersistent: isper })
}

export async function Contact(userName) {
    return await createAxios(OrgId()).post("ApplicationUser/ForgotPasswordInitiate?userName=" + userName)
}

export async function RefreshLogin(userName, otp, newPass, confirmPassword) {
    return await createAxios(OrgId()).post("ApplicationUser/ForgotPassword?UserName=" + userName + "&OTP=" + otp + "&NewPass=" + newPass + "&ConfirmPassword=" + confirmPassword)
}
export async function ConfigureApp(passcode) {
    return await createAxios(OrgId()).post("ConfigDb/Init?code=" + passcode)
}

export async function GetIP() {
    return await createAxiosSimple.get("https://ipapi.co/json/")
}