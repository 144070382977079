import React, { useEffect, useState } from 'react'
import {
    loadCaptchaEnginge, LoadCanvasTemplate,
    validateCaptcha
} from 'react-simple-captcha';

function MyCaptcha({ count = 6, setResult }) {
    const [ctext, setCtext] = useState("");

    useEffect(() => {
        loadCaptchaEnginge(count, "black", "green");
    }, [])

    const setText = (e) => {
        const { value } = e.target
        setResult(value)
        setCtext(value)
    }

    return (
        <>
            <LoadCanvasTemplate reloadText="<i class='ace-icon fa fa-refresh'></i> Reload" reloadColor="#5090C1" />
            <input className="form-control" type="text" style={{width: "150px", float: "right"}} placeholder="कॅप्टचा डेल" value={ctext} onChange={setText} />
        </>
    )
}
export { MyCaptcha, validateCaptcha }
